import { Typography } from '@mui/material';
import MenuDrawer from '../components/global/MenuDrawer';

function ContactPage() {
  return (
    <>
      <MenuDrawer />

      <Typography align="center" sx={{marginTop:20, marginBottom:5}} >
        {"ΣΤΟΙΧΕΙΑ ΕΠΙΚΟΙΝΩΝΙΑΣ"}
      </Typography>

      <Typography marginBottom= '30px'  marginLeft="20px">
        <strong>{"Γενικές Πληροφορίες: "} </strong> 
        {"info@photometria.gr"}
      </Typography>
      <Typography marginBottom= '30px'  marginLeft="20px">
        <strong>{"Χορηγίες & Δημόσιες σχέσεις: "} </strong> 
        {"press@photometria.gr"}
      </Typography>
      <Typography marginBottom= '30px'  marginLeft="20px">
        <strong>{"Διαγωνισμός Photometria Awards: "} </strong> 
        {"competition@photometria.gr"}
      </Typography>
      <Typography marginBottom= '30px'  marginLeft="20px">
        <strong>{"Διαγωνισμοί Portfolio: "} </strong> 
        {"portfolio@photometria.gr"}
      </Typography>
      <Typography marginBottom= '30px'  marginLeft="20px">
        <strong>{"Τμήμα προβολών: "} </strong> 
        {"films@photometria.gr"}
      </Typography>
      <Typography marginBottom= '30px'  marginLeft="20px">
        <strong>{"Εκπαιδευτικά Προγράμματα: "} </strong> 
        {"seminars@photometria.gr"}
      </Typography>
    </>
  );
}

export default ContactPage;