import { Typography } from '@mui/material';
import MenuDrawer from '../components/global/MenuDrawer';

function PrivacyPolicyPage() {
  return (
    <>
      <MenuDrawer />
      <Typography 
          fontSize={20} 
          color="black"
          marginTop={20}
          marginBottom={5}
          padding={1}
          textAlign="center"
        >
          {"© Photometria International Photography Festival"}
        </Typography>
    </>
  );
}

export default PrivacyPolicyPage;