import { Box, Typography, Button } from "@mui/material";
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';

function Error() {

  function handleRefresh() {
    window.location.reload()
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: "center"
      }}
    >
      <Typography fontSize={70} fontWeight='bold' sx={{marginBottom: '10px'}}>
        {'Oops!'}
      </Typography>
      <Typography>
        {'Κάτι πήγε στραβά...:('}
      </Typography>
      <Typography>
        {'Μπορείτε να ανανεώσετε τη σελίδα και να δοκιμάσετε ξανά.'}
      </Typography>

      <Button
        sx={{ fontWeight: 400, margin: 3, borderRadius: 0 }}
        component="label"
        color="error"
        onClick={handleRefresh}
        startIcon={<RefreshRoundedIcon />}
      >
        {"ΑΝΑΝΕΩΣΗ ΣΕΛΙΔΑΣ"}
      </Button>
    </Box>
  );
}

export default Error;