import {Box, CircularProgress} from '@mui/material';
import { colors } from '../../config/colors';

function Loading({ width, height }) {
  return (
    <Box
      sx={{
        width: width ? width : '100%',
        height: height ? height : '90vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <CircularProgress sx={{color: colors.black}} />
    </Box>
  );
}

export default Loading;