import React from 'react';
import MenuDrawer from '../components/global/MenuDrawer';
import ExhibitionsMap from '../components/map/ExhibitionsMap';

function MapPage() {
  return (
    <>
      <MenuDrawer />
      <ExhibitionsMap />
    </>
  );
};

export default MapPage;