import { Typography } from '@mui/material';
import MenuDrawer from '../components/global/MenuDrawer';

function PartnersPage() {
  return (
    <>
      <MenuDrawer />
      <Typography 
          fontSize={20} 
          color="black"
          marginTop={20}
          marginBottom={5}
          padding={1}
          textAlign="center"
        >
          {"Με την οικονομική υποστήριξη και υπό την αιγίδα του Υπουργείου Πολιτισμού και Αθλητισμού, του Δήμου Ιωαννιτών και της Περιφέρειας Ηπείρου"}
        </Typography>
        <img
          src="partners1.jpg"
          alt="partners"
          style={{
            maxWidth: '100%',    
            height: 'auto',       
            width: 'auto'        
          }}
        />
    </>
  );
}

export default PartnersPage;
