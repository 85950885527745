export const screeningsData = [
  {
    "id": 0,
    "attributes": {
      "title": "Δευτέρα 30/9, 20:00",
      "subtitle": "Photometria in Motion, Κατηγορία: Ταινίες Μικρού Μήκους (1ο μέρος), 144′",
      "description": 
        "Hunting, 5′, Maïa Aboualiten [Γαλλία]\n\n" +
        "WE ARE ALL DOGS, 14′, Aminreza Alimohammadi [Ιράν]\n\n" +
        "Being one, 17′18′′, Juan Pablo Aróstegui [Μεξικό]\n\n" +
        "We Are Happy, 19′26′′, Mohamad Boorboor [Ιράν]\n\n" +
        "Homo, 7′58′′, Yaroslav Vyacheslavovich Naumenko [Ουκρανία]\n\n" +
        "From the Dark, 6′53′′, Erika Sanz [Ην. Πολιτείες Αμερικής]\n\n" +
        "From The Painter’s View, 16′22′′, Goli Zarei [Ιράν]\n\n" +
        "Predators, 10′, Κυριάκος Αναστασίου [Ελλάδα]\n\n" +
        "The Best, 10′, Ηλίας Δούλης [Ελλάδα]\n\n" +
        "I WONDER,YES I CONFESS, I WONDER, 10′31′′, Αντωνία Οικονόμου, Χρήστος Ανδριανόπουλος [Ελλάδα]\n\n" +
        "Another Day, 7′14′′, Alloprosallos Nous [Ελλάδα]\n\n" +
        "Chlorine, 20′, Θάνος Ψυχογιός [Ελλάδα]",
      "thumbnail": "media/HOMO-300x200.jpg"
    }
  },
  {
    "id": 1,
    "attributes": {
      "title": "Τρίτη 1/10, 20:00",
      "subtitle": "Photometria in Motion, Κατηγορία: Ταινίες Μικρού Μήκους (2ο μέρος), 139′",
      "description": 
        "THE SINGLE HORN, 13′28′′, Mohamad Kamal Alavi [Ιράν]\n\n" +
        "Najan, 15′, M.Mahdi Bagheri [Ιράν]\n\n" +
        "Fortissimo, 14′53′′, Victor Cesca [Γαλλία]\n\n" +
        "Nonsense!, 20′′, Strahinja Mlađenović [Σερβία]\n\n" +
        "Boycott, 13′16′′, Amin Rafie [Ιράν]\n\n" +
        "The Land, 8′25′′, Masoud Shahri [Ιράν]\n\n" +
        "School benches, 12′15′′, Behrouz Shamshiri [Ιράν]\n\n" +
        "Father, 19′49′′, Steven Gekas [Ελλάδα]\n\n" +
        "Axinos, 5′, Άννα Μαρία Κάκονα, Καλλιόπη Βίλλη Κωτούλα [Ελλάδα]\n\n" +
        "Toy, 11′, Νατάσσα Σμυρναίου [Ελλάδα]\n\n" +
        "Hantush – a fairytale, 6′12′′, Αλεξία Τσουνή [Ελλάδα]",
      "thumbnail": "media/The-Land-300x200.jpg"
    }
  },
  {
    "id": 2,
    "attributes": {
      "title": "Τετάρτη 2/10, 20:00",
      "subtitle": "Photometria in Motion, Κατηγορία: Ταινίες Μικρού Μήκους (3ο μέρος), 144′",
      "description": 
        "Entropy, 14′18′′ , Bamdad Aghajani, Arian Navabi [Ιράν]\n\n" +
        "Good to talk, 9′33′′ , Stephen Broekhuizen [Ιρλανδία]\n\n" +
        "si mai no ens haguéssim separate, 14′13′′  , Marc Esquirol Cano [Ισπανία]\n\n" +
        "Double bed, 6′17′′, Ali Khameparast Fard [Ιράν]\n\n" +
        "Tornado, 6′17′′, Meisam Gheisizadeh [Ιράν]\n\n" +
        "góos, 14′30′′, Φλώρα Ηλία [Ελλάδα]\n\n" +
        "Xénia, 10′, Αλέξανδρος Κακούρης [Ελλάδα]\n\n" +
        "Voulolimna, 18′, Νεφέλη Παπαϊωάννου [Ελλάδα]\n\n" +
        "The Boar, 20′, Κωνσταντίνος Τσιόδουλος [Ελλάδα]",
      "thumbnail": "media/The-Boar-300x162.jpg"
    }
  },
  {
    "id": 3,
    "attributes": {
      "title": "Πέμπτη 3/10, 20:00",
      "subtitle": "Photometria in Motion, Κατηγορία: Ντοκιμαντέρ, 45′",
      "description": 
        "Transient Ties, 8′44′′, Michaela Nagyidaiová [Σλοβακία]\n\n" +
        "Courage, 15′30′′, Star Bazancir, Jasmina Pusök [Σουηδία]\n\n" +
        "The Life Beyond, Στυλιανός Παπαρδέλας [Ελλάδα]",
      "thumbnail": "media/The-Life-Beyond-Στυλιανός-Παπαρδέλας-300x200.jpg"
    }
  },
  {
    "id": 4,
    "attributes": {
      "title": "Παρασκευή 4/10, 20:00",
      "subtitle": "Photometria in Motion, Κατηγορία: Animation, 100′",
      "description": 
        "PINA, 20′, Jeremy Depuydt, Giuseppe Accardo [Βέλγιο]\n\n" +
        "Txotxongiloa, 10′, Sonia Estévez [Ισπανία]\n\n" +
        "Golden Shot, 8′40′′, Gokalp Gonen [Τουρκία]\n\n" +
        "Don’t forget to take medicines on time, 2′27′′, Xiaoxuan Han [Κίνα]\n\n" +
        "Swwart Wovlve Wolve, 8′, William Harper [Ην. Πολιτείες Αμερικής]\n\n" +
        "Anemometer, 6′, Wang-Chen Lee [Γερμανία]\n\n" +
        "Winking out, 17′, Juliette Mauduit [Βέλγιο]\n\n" +
        "Monk SEISHIN, 2′53′′, Ryotaro Miyajima [Ιαπωνία]\n\n" +
        "Shakespeare for all Ages, 2′42′′, Hannes Rall [Γερμανία]\n\n" +
        "NUMBERS, 9′, Deniz Türker [Τουρκία]\n\n" +
        "STARRY WILL, 2′14′′, Jiajing Yin [Κίνα]\n\n" +
        "Pink Mountain, 8′25′′, Thomas Kunstler [Ελλάδα]",
      "thumbnail": "media/Txotxongiloa-Sonia-Estevez-300x169.jpg"
    }
  },
  {
    "id": 5,
    "attributes": {
      "title": "Σάββατο 5/10, 20:00",
      "subtitle": "Photometria in Motion, Κατηγορία: Video Art, 96′",
      "description": 
        "Urban Cut – Agonia Stratos, 3′, Lea Alquiroz [Ην. Βασίλειο]\n\n" +
        "Hungry Ghost, Guido Devadder, 6′52′′, Kaitlin McSweeney [Βέλγιο]\n\n" +
        "Motus, 4′10′′, Nelson Fernandes [Πορτογαλία]\n\n" +
        "Periodic Table, 5′21′′, Jason Franzen [Ην. Πολιτείες Αμερικής]\n\n" +
        "AbstrArt 27, 6′20′′, Luis Carlos Rodríguez García [Ισπανία]\n\n" +
        "Flights, 4′53′′, Anne Gart [Ην. Βασίλειο]\n\n" +
        "Night Ride from LA, 5′, Martin Gerigk [Γερμανία]\n\n" +
        "Acrobat, 13′28′′, Shin Jungkyun [Κορέα]\n\n" +
        "Simulakrum, 4′14′′, Vera Sebert [Αυστρία]\n\n" +
        "The Empathy Machine, 9′28′′, Grant Sissons [Νότια Αφρική]\n\n" +
        "KYKLOP, 15′, Telemach Wiesinger [Γερμανία]\n\n" +
        "the manifest of it, 20′, Κωστής Αργυριάδης [Ελλάδα]\n\n" +
        "The Life We Live Is Not Life Itself, 8′45′′, Ian Gibbins [Ελλάδα]",
      "thumbnail": "media/Photo-1-000000023-300x168.jpg"
    }
  },
  {
    "id": 6,
    "attributes": {
      "title": "Δευτέρα 7/10, 20:00",
      "subtitle": "Ο Περικλής στην Αμερική, John Cohen, 1988, 68′",
      "description": "Η ταινία αυτή φιλοτεχνεί το μουσικό πορταίτο του Περικλή Χαλκιά, κλαρινίστα μετανάστη, και της ηπειρώτικης κοινότητας στην Αμερική, εξερευνώντας τις φιλοδοξίες, αλλά και την αμφιθυμία των Ελληνοαμερικανών. Καθώς κινείται μεταξύ Κουίνς, Νέας Υόρκης και βόρειας Ελλάδας, παρουσιάζει την παραδοσιακή μουσική της Ηπείρου και το πως αυτή ενώνει την Ηπειρώτικη κοινότητα σε όλο τον κόσμο. Η ταινία δεν βλέπει την Αμερική ως ένα χωνευτήρι λαών, αλλά ως ένα μέρος για να φτιάξει κανείς μια καλύτερη ζωή. Οι Ηπειρώτες που κερδίζουν το ψωμί τους εδώ έχουν γερά ριζωμένη την καρδιά τους στα βουνά της Ελλάδας.",
      "thumbnail": "media/Screenshot-2024-07-17-at-12.15.12-PM-1-300x238.png"
    }
  },
];