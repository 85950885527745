import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { API_CALL_DELAY } from '../../config/api';
import Loading from '../global/Loading';
import Error from '../global/Error';
import { aboutUsData } from '../../data/aboutUsData';

function AboutUsContent() {
  const [informations, setInformations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const runQuery = async () => {
      try {
        setInformations(aboutUsData);
      } catch (error) {
        setError(error.message);
      } finally {
        setTimeout(() => setIsLoading(false), API_CALL_DELAY);
      }
    };
    runQuery();
  }, []);

  if (isLoading) return <Loading />;
  if (error) return <Error />;

  return (
    <Box sx={{ padding: 4 }}>
      <Typography variant="h4" marginTop={"100px"} gutterBottom fontFamily='Comfortaa'>
        {"Πληροφορίες"} 
      </Typography>

      <Typography marginTop={5} variant="body1" paragraph>
        Το <strong>Photometria International Photography Festival</strong> είναι μια διοργάνωση της αστικής, μη κερδοσκοπικής «Έντευξις». Διοργανώνεται για 16η χρονιά στην πόλη των Ιωαννίνων και πρόκειται για το πρώτο διαδραστικό Φεστιβάλ στο οποίο έχουν την δυνατότητα να συμμετέχουν όσοι αγαπούν την τέχνη της φωτογραφίας.
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>Όραμά</strong> μας είναι το Photometria International Photography Festival να αποτελέσει πεδίο προβολής της σύγχρονης φωτογραφίας, της υποστήριξης νέων Ελλήνων και ξένων φωτογράφων, της εκπαίδευσης, καθώς και της καλλιέργειας συνεργασιών με πολιτιστικούς οργανισμούς και φεστιβάλ της Ελλάδας και άλλων χωρών.
      </Typography>
      <Typography variant="body1" paragraph>
        Ευελπιστούμε το Photometria International Photography Festival να είναι ένας τόπος δια-πολιτιστικής συνάντησης, μεταφέροντας αντιλήψεις, παραδόσεις, κοσμοθεωρίες, και εκφραστικά μέσα από όλα τα σημεία του πλανήτη. Με αφοσίωση στο εγχείρημα και ευχόμενοι την καθιέρωση του, οι διοργανωτές θέσαμε από την πρώτη στιγμή ως στόχο την πρόσκληση για συμμετοχή, όχι μόνο καταξιωμένων φωτογράφων αλλά και ερασιτεχνών να λάβουν μέρος στο πεδίο της έκφρασης. Προσδοκία μας είναι να αποτελέσει το Photometria International Photography Festival μία πολιτιστική ομπρέλα κάτω από την οποία θα καλλιεργείται η φωτογραφική έκφραση και η δημιουργικότητα. Ετοιμαστείτε λοιπόν για άλλο ένα Photometria γεμάτη δημιουργικότητα, εκθέσεις, σεμινάρια και φωτογραφικές περιπλανήσεις.
      </Typography>

      <Typography marginTop={5} marginBottom={2} variant="h5" gutterBottom fontFamily='Comfortaa'>
        Συντελεστές
      </Typography>

      {informations.map((info, index) => (
        <Typography key={index} variant="body1" paragraph>
          <strong>{info.attributes.role}</strong>: {info.attributes.name}
        </Typography>
      ))}

      <Typography marginTop={7} />

      {informations.slice(0, 8).map((information, index) => {
        const image = information.attributes.image;
        const title = information.attributes.title;
        const role = information.attributes.role;
        const description = information.attributes.description;

        return (
          <Box key={index} sx={{ marginBottom: 2 }}>
            <Typography variant="body1" component="span" fontWeight="bold">
              {role}
            </Typography>
            <img 
              src={image} 
              alt={title || "img"} 
              style={{ 
                width: '100%', 
                height: 'auto', 
                maxWidth: '150px', 
                display: 'block', 
                marginTop: '8px'  
              }} 
            />
            <Typography variant="body1" component="span" >
              {description}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
}

export default AboutUsContent;
