import React, { useState } from 'react';
import { routes } from "../../config/routes";
import { Box, Typography, Button, Drawer, IconButton } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import MenuDrawerItems from './MenuDrawerItems';
import { colors } from '../../config/colors';
import { useLocation } from 'wouter';

function MenuDrawer() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [, setLocation] = useLocation();

  function handleDrawerOpen() {
    setDrawerOpen(true);
  };

  function handleDrawerClose() {
    setDrawerOpen(false);
  };

  function navigateToWelcomePage() {
    setLocation(routes.welcome); 
  }

  return (
    <>
      <Box
        sx={{
          backgroundColor: colors.backgroundYellow,
          padding: '1rem 2rem', 
          height: 'auto',
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          zIndex: 1000,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Button
          sx={{
            display: { xs: 'block', md: 'none' },
            fontWeight: '500',
            minWidth: 'auto',
            padding: 1,
          }}
          onClick={handleDrawerOpen}
        >
          <MenuIcon
            sx={{
              fontSize: '2.5rem',
              color: 'black',
            }}
          />
        </Button>

        <Box 
          sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center', 
            flexGrow: 1 
          }}
          onClick={() => navigateToWelcomePage()}
        >
          <Typography
            sx={{
              fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' },
              fontWeight: '800',
              color: 'black',
              textAlign: 'center',
            }}
          >
            {"Photometria 2024"}
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: '0.75rem', sm: '1rem', md: '1.25rem' },
              fontWeight: '500',
              color: 'black',
              textAlign: 'center',
            }}
          >
            {"INTERNATIONAL PHOTOGRAPHY FESTIVAL"}
          </Typography>
        </Box>
        
        <Box sx={{ display: { xs: 'none', md: 'block' } }}> 
          <Button
            sx={{
              fontWeight: '500',
              minWidth: 'auto',
              padding: 1,
            }}
            onClick={handleDrawerOpen}
          >
            <MenuIcon
              sx={{
                fontSize: '2.5rem',
                color: 'black',
              }}
            />
          </Button>
        </Box>
      </Box>

      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={handleDrawerClose}
        PaperProps={{
          sx: { width: '100%', backgroundColor: colors.backgroundYellow },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2,
          }}
        >
          <IconButton onClick={handleDrawerClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <MenuDrawerItems />
      </Drawer>
    </>
  );
};

export default MenuDrawer;