import AboutUsContent from "../components/about-us/AboutUsContent";
import MenuDrawer from "../components/global/MenuDrawer";

function AboutUsPage() {
  return (
    <>
      <MenuDrawer />
      <AboutUsContent />
    </>
  );
}

export default AboutUsPage;