import MenuDrawer from "../components/global/MenuDrawer";
import ExhibitionsContent from "../components/exhibitions/ExhibitionsContent";

function ExhibitionsPage() {
  return (
    <>
      <MenuDrawer />
      <ExhibitionsContent />
    </>
  );
}

export default ExhibitionsPage;