import { Typography, Box, Button, Alert } from "@mui/material";
import { useLocation } from "wouter";
import { routes } from "../config/routes";
import { useEffect, useState } from "react";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";

function WelcomePage() {
  const [, setLocation] = useLocation();

  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isInstallable, setIsInstallable] = useState(false);
  const [showIosAlert, setShowIosAlert] = useState(false);

  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
      setIsInstallable(true);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  function handleAndroidInstallClick() {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the A2HS prompt');
        } else {
          console.log('User dismissed the A2HS prompt');
        }
        setDeferredPrompt(null);
        setIsInstallable(false);
      });
    }
  };

  function handleIosInstallClick() {
    setShowIosAlert(true);
  }

  function isIOS() {
    return (
      /iPad|iPhone|iPod/.test(navigator.userAgent) &&
      !window.MSStream
    );
  };

  function handleImageClick() {
    setLocation(routes.introductionMenu); 
  }

  return (
    <>
      {isIOS()  && (
        <Button
          onClick={handleIosInstallClick}
          variant='contained'
          sx={{
            borderRadius: 1,
            backgroundColor: 'black',
            margin: 2
          }}
          startIcon={<DownloadRoundedIcon />}
        >
          {'install'}
        </Button>
      )}
      {isInstallable && (
        <Button
          onClick={handleAndroidInstallClick}
          variant='contained'
          sx={{
            borderRadius: 1,
            backgroundColor: 'black',
            margin: 2
          }}
          startIcon={<DownloadRoundedIcon />}
        >
          {'install'}
        </Button>
      )}
      {showIosAlert && (
        <Alert 
          severity="info" 
          onClose={() => setShowIosAlert(false)}  
          sx={{ position: 'absolute', top: 0, width: '90%' }}
        >
          {"To install this app on your iOS device, tap the share icon and then 'Add to Home Screen.'"}
        </Alert>
      )}
      <Box 
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          width: '100vw', 
          height: '80vh',
          boxSizing: 'border-box',
          textAlign: 'center',
          position: 'relative',
        }}
        onClick={handleImageClick}
      >
        <Typography 
          fontSize={{xs: 24, sm: 28, md: 30}} 
          paddingBottom={{xs: 2, sm: 3, md: 5}}  
          fontWeight={900} 
        >
          {"Constructed Realities"}
        </Typography>
        <img 
          src="photometriaImage.png" 
          alt="Logo" 
          style={{ 
            width: "80%", 
            maxWidth: '400px',
            height: "auto", 
            marginBottom: "20px" 
          }} 
        />
        <Typography 
          fontSize={{ xs: 30, sm: 30, md: 35 }} 
          paddingBottom={{ xs: 1, sm: 2, md: 0 }} 
          fontWeight={900} 
        >
          {'Photometria'}
        </Typography>
        <Typography 
          fontSize={{ xs: 15, sm: 16, md: 17 }} 
          paddingBottom={{ xs: 2, sm: 3, md: 10 }} 
          fontWeight={700}  
        >
          {'INTERNATIONAL PHOTOGRAPHY FESTIVAL'}
        </Typography>
        <Box
          sx={{
            position: 'fixed',
            bottom: 0,
            right: 0,
            padding: 0,
          }}
        >
          <img 
            src='outro.png'
            alt="Outro" 
            style={{ 
              width: "50%", 
              height: "auto", 
              marginBottom: "5px"}} 
          />
        </Box>
      </Box>
    </>
  );
}

export default WelcomePage;
