import { useLocation } from 'wouter';
import { Typography, Box, Button } from "@mui/material";
import { menuDrawerItems } from '../../config/menuItems';
import { routes } from '../../config/routes';

function MenuDrawerItems() {
  const [, setLocation] = useLocation();

  function navigateTo(route) {
    setLocation(route);
  };
 
  return (
    <Box 
      sx={{
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        width: "100vw",
        height:"100vh"
      }}
    >
      <Box
          sx={{
          color: 'black',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: 0,
            paddingTop: 0,
            marginTop: 0,
            paddingBottom: 5,
            marginRight: 25,
            fontFamily: 'Comfortaa',
          }}  
        >
          {menuDrawerItems[0].map((item, index) => (
            <Button key={index} onClick={() => navigateTo(item.route)} sx={{color: 'black', fontSize: '1.5rem', }}>
              {item.name}
            </Button>
          ))}

        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: 0,
            paddingTop: 0,
            marginTop: 0,
            paddingBottom: 0,
            marginRight: 20,
            fontFamily: 'Comfortaa',
          }}  
        >
          {menuDrawerItems[1].map((item, index) => ( 
            <Button key={index} onClick={() => navigateTo(item.route)} sx={{color: 'black', fontSize: '1.5rem', }}>
              {item.name}
            </Button>
          ))}
        </Box>
      
      <Box onClick={() => navigateTo(routes.welcome)}>
        <Typography 
          fontSize={50} 
          paddingBottom={0} 
          paddingTop={6} 
          fontWeight={800} 
        >
          {"Photometria"}
        </Typography>
        <Typography 
          fontSize={15} 
          paddingBottom={5} 
          fontWeight={500}  
        >
          {"INTERNATIONAL PHOTOGRAPHY FESTIVAL"}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: "flex-start",
          paddingLeft: "50%"
        }}
      >
        <img 
          src='outro.png'
          alt="Outro" 
          style={{ width: "200px", height: "auto", marginBottom: 5}} 
        />
      </Box>
    </Box>
  );
}

export default MenuDrawerItems;
