import { Switch, Route } from 'wouter';
import WelcomePage from './pages/WelcomePage';
import IntroductionMenuPage from './pages/IntroductionMenuPage';
import MapPage from './pages/MapPage';
import AboutUsPage from './pages/AboutUsPage';
import { routes } from './config/routes';
import PartnersPage from './pages/PartnersPage';
import ExhibitionsPage from './pages/ExhibitionsPage';
import ScreeningsPage from './pages/ScreeningsPage';
import EventsPage from './pages/EventsPage';
import ContactInfoPage from './pages/ContactPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';

function App() {
  
  return ( 
    <Switch>
      <Route path={routes.introductionMenu} component={IntroductionMenuPage} /> 
      <Route path={routes.exhibitions} component={ExhibitionsPage} />
      <Route path={routes.map} component={MapPage} />
      <Route path={routes.aboutUs} component={AboutUsPage} />
      <Route path={routes.screenings} component={ScreeningsPage} />
      <Route path={routes.events} component={EventsPage} />
      <Route path={routes.partners} component={PartnersPage} />
      <Route path={routes.contact} component={ContactInfoPage} />
      <Route path={routes.privacyPolicy} component={PrivacyPolicyPage} />
      <Route path={routes.welcome} component={WelcomePage} />
    </Switch>
  );
}

export default App;

