import { useState, useEffect } from "react";
import Loading from "../global/Loading";
import Error from "../global/Error";
import { Grid, Box, Typography } from "@mui/material";
import ScreeningsDialog from "./ScreeningsDialog";
import { colors } from "../../config/colors";
import { screeningsData } from "../../data/screeningsData";

function ScreeningsContent() {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [screenings, setScreenings] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedScreening, setSelectedScreening] = useState(null);

  useEffect(() => {
    const runQuery = async () => {
      try {
        setScreenings(screeningsData);
      } catch (err) {
        setError(err);
      } finally {
        setIsLoading(false);
      }
    };
    runQuery();
  }, []);

  function handleScreeningClick(screening) {
    setSelectedScreening(screening);
    setOpen(true);
  };

  if (isLoading) return <Loading />;
  if (error) return <Error />;
  if (screenings.length === 0) return <>No content available!</>;

  return (
    <>
      <Grid container spacing={4} sx={{ padding: 4, marginTop: 8 }}>
        {screenings.map((screening) => {
          const title = screening.attributes.title;
          const description = screening.attributes.description;
          const imageUrl = screening.attributes.thumbnail;

          return (
            <Grid item xs={12} sm={6} md={4} key={screening.id} >
              <Box
                sx={{
                  marginBottom: 1.5,
                  padding: 1.5,
                  boxShadow: '0 3px 6px rgba(0, 0, 0, 0.1)',
                  borderRadius: 2,
                  backgroundColor: colors.lightYellow, 
                }}
                onClick={() => handleScreeningClick({ title, description, imageUrl })}
              >
                <Typography
                  sx={{
                    fontSize: '1rem',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    marginBottom: 2,
                  }}
                >
                  {title}
                </Typography>
                  <Box
                    sx={{
                      height: 250,
                      width: '100%',
                      maxWidth: 350,
                      overflow: 'hidden',
                      borderRadius: 2,
                    }}
                  >
                    <img
                      src={imageUrl}
                      alt={title}
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        borderRadius: '8px',
                      }}
                    />
                  </Box>
              </Box>
            </Grid>
          );
        })}
      </Grid>
      <ScreeningsDialog
        selectedScreening={selectedScreening}
        setSelectedScreening={setSelectedScreening}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
}

export default ScreeningsContent;