import { useState, useEffect } from "react";
import Loading from "../global/Loading";
import Error from "../global/Error";
import { Grid, Box, Typography } from "@mui/material";
import ExhibitionsDialog from "./ExhibitionDialog";
import { colors } from "../../config/colors";
import { exhibitionsData } from "../../data/exhibitionsData";

function ExhibitionsContent() {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [exhibitions, setExhibitions] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedExhibition, setSelectedExhibition] = useState(null);

  useEffect(() => {
    const runQuery = async () => {
      try {
        setExhibitions(exhibitionsData);
      } catch (err) {
        setError(err);
      } finally {
        setIsLoading(false);
      }
    };
    runQuery();
  }, []);

  function handleExhibitionClick(exhibition) {
    setSelectedExhibition(exhibition);
    setOpen(true);
  };

  if (isLoading) return <Loading />;
  if (error) return <Error />;
  if (exhibitions.length === 0) return <>No content available!</>;

  return (
    <>
      <Grid container spacing={4} sx={{ padding: 4, marginTop: 8 }}>
        {exhibitions.map((exhibition) => {
          const title = exhibition.attributes.title;
          const location = exhibition.attributes.location?.name;
          const description = exhibition.attributes.description;
          const imageUrl = exhibition.attributes.thumbnail;
          const duration = exhibition.attributes.duration;

          return (
            <Grid item xs={12} sm={6} md={4} key={exhibition.id} >
              <Box
                sx={{
                  marginBottom: 1.5,
                  padding: 1.5,
                  boxShadow: '0 3px 6px rgba(0, 0, 0, 0.1)',
                  borderRadius: 2,
                  backgroundColor: colors.lightYellow, 
                }}
                onClick={() => handleExhibitionClick({ title, location, description, imageUrl, duration })}
              >
                <Typography
                  sx={{
                    fontSize: '1rem',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    marginBottom: 2,
                  }}
                >
                  {title}
                </Typography>
                <Box
                  sx={{
                    height: 250,
                    width: '100%',
                    maxWidth: 350,
                    overflow: 'hidden',
                    borderRadius: 2,
                  }}
                >
                  <img
                    src={imageUrl}
                    alt={title}
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                      borderRadius: '8px',
                    }}
                  />
                </Box>
              </Box>
            </Grid>
          );
        })}
      </Grid>
      <ExhibitionsDialog
        selectedExhibition={selectedExhibition}
        setSelectedExhibition={setSelectedExhibition}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
}

export default ExhibitionsContent;