import { Dialog, DialogTitle, DialogContent, DialogActions, Box, Typography, Button } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { colors } from "../../config/colors";

function ScreeningsDialog({ selectedScreening, setSelectedScreening, open, setOpen }) {
  
  function handleCloseButtonClick() {
    setOpen(false);
    setSelectedScreening(null);
  };

  return (
    <Dialog 
      open={open} 
      onClose={handleCloseButtonClick} 
      fullScreen 
    >
      <DialogTitle 
        sx={{ 
          backgroundColor: colors.lightYellow, 
          fontWeight: 'bold', 
          textAlign: 'center',
          padding: '16px',
        }}
      >
        {selectedScreening?.title}
      </DialogTitle>

      <DialogContent 
        sx={{ 
          backgroundColor: colors.lightYellow, 
          padding: '40px 20px', 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center',
        }}
      >
        {selectedScreening?.imageUrl && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: '20px',
            }}
          >
            <img
              src={selectedScreening.imageUrl}
              alt={selectedScreening.title}
              style={{
                width: '100%',
                height: 'auto',
                borderRadius: 8,
                maxWidth: '100%',
              }}
            />
          </Box>
        )}
        <Typography 
          variant="body1" 
          sx={{ 
            color: '#333', 
            textAlign: 'left', 
            whiteSpace: 'pre-line',
          }}
        >
          {selectedScreening?.description || 'No description available'}
        </Typography>
      </DialogContent>

      <DialogActions 
        sx={{ 
          backgroundColor: colors.lightYellow, 
          justifyContent: 'center',
          padding: '10px',
        }}
      >
        <Button 
          onClick={handleCloseButtonClick} 
          variant="contained" 
          sx={{ 
            borderRadius: '10px', 
            display: 'flex', 
            alignItems: 'center', 
            backgroundColor: colors.lightYellow, 
  
            color: 'primary.main', 
            boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.3)',
            border: '2px solid rgba(0, 0, 0, 0.1)', 
          }}
        >
          <CloseIcon sx={{ color: 'black' }} />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ScreeningsDialog;