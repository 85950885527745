export const menuItems = [
  {
    name: "MAP",
    route: "/map"
  },
  {
    name: "ΕΧΗΙΒΙΤΙΟΝS",
    route: "/exhibitions"
  },
  {
    name: "EVENTS",
    route: "/events"
  },
  {
    name: "SCREENINGS",
    route: "/screenings"
  },
  {
    name: "ABOUT US",
    route: "/about-us"
  }
]

export const menuDrawerItems = [
  [
    {
      name: "HOME",
      route: "/introduction-menu",
    },
    {
      name: "EXHIBITIONS",
      route: "/exhibitions",
    },
    {
      name: "SCREENINGS",
      route: "/screenings",
    },
    {
      name: "EVENTS",
      route: "/events",
    },
    {
      name: "MAP",
      route: "/map",
    },
  ],
  [
    {
      name: "ABOUT US",
      route: "/about-us",
    },
    {
      name: "CONTACT",
      route: "/contact",
    },
    {
      name: "PARTNERS",
      route: "/partners",
    },
    {
      name: "PRIVACY POLICY",
      route: "/privacy-policy",
    }
  ]
]