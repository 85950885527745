import MenuDrawer from "../components/global/MenuDrawer";
import ScreeningsContent from "../components/screenings/ScreeningsContent";

function ScreeningsPage() {
  return (
    <>
      <MenuDrawer />
      <ScreeningsContent />
    </>
  );
}

export default ScreeningsPage;